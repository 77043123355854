import { render, staticRenderFns } from "./userPop.vue?vue&type=template&id=6fc63412"
import script from "./userPop.vue?vue&type=script&lang=js"
export * from "./userPop.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\NewDevelopment\\PROJECT\\KNCO\\FrontEnd\\KNCOFrontEnd\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6fc63412')) {
      api.createRecord('6fc63412', component.options)
    } else {
      api.reload('6fc63412', component.options)
    }
    module.hot.accept("./userPop.vue?vue&type=template&id=6fc63412", function () {
      api.rerender('6fc63412', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/common/user/userPop.vue"
export default component.exports